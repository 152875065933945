html{
  background-color: #222222;
}

.root-app{
  background-color: #222222;
  color:#FFFFFF;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}


@media only screen and (min-width : 480px) {
  .root-mobile-view{
    width:480px;
    margin:auto
  }

}
