.dialogHeaderStyle{
    font-weight: 700;
font-size: 16px;
line-height: 20px;
}

.dialogBodyStyle{
    font-weight: 400;
font-size: 16px;
line-height: 24px;
}