.detailsPageContainer{
    position: relative;
}

.detailsCardSection{
    /* position: absolute; */
    /* background-color: #222222; */
    width: 100%;
}

.inputFormContainer{
    margin:0px 16px;
    background-color: #404246;
    padding:16px;
    margin-top: -36px;
    border-radius: 8px;
}

.detailsBannerContainer{
    width:100%;
    background-repeat: no-repeat;
    height: 250px;
    background-size: cover;
}

.cardListingSection{
    margin:24px 16px;
    position: relative;
}

.cardListingSection > span{
    text-align: center;
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #ADA6AE;
}

.cardListingContainerActive{
    position: relative;
    height: 74px;
    width: 100%;
    background: linear-gradient(88.47deg, #4DD4FF 0%, #2F5CCF 100%);
    border-radius: 8px;
    margin-bottom: 6px;
    overflow: hidden;
}

.cardListingContainerInactive{
    position: relative;
    height: 74px;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 6px;
background: rgba(182, 188, 191, 0.2);
border-radius: 8px;
}

.cardTextContainer span{
    display: block;

}

.cardTextContainer span:nth-child(1){
    font-weight: 700;
font-size: 16px;
line-height: 24px;
/* display: flex; */
align-items: center;
text-align: center;
}


.cardTextContainer span:nth-child(2){
    font-weight: 700;
font-weight: 400;
font-size: 12px;
line-height: 18px;
}

/* 404141 */

.ellipseRight{
    position: absolute;
    right: 0px;
    top: 0px;
}

.ellipseSecondary{
    position: absolute;
    bottom: 0px;
    right: 10px
}

.rectangleLeft{
    position: absolute;
    bottom: 0px;
}

.starLeft{
    position: absolute;
    left: 0px;
    top:10px;
}


.cardTextContainer{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:51%;
    text-align: center;
}

.cardBottomActions{
    margin-top: 26px;
    text-align: center;
}

.expandContainer{
    margin-bottom: 16px;
}

.cardBottomActions span{
    display: block;
    font-weight: 600;
font-size: 12px;
line-height: 18px;
color:#FFFFFF;
}

.termsConditionText{
    font-weight: 400;
font-size: 10px;
line-height: 12.5px;
letter-spacing: 0.1px;
width: 100%;
margin: 14px 0px;
text-align: center;
color:#ADA6AE
}

.bottomTextPosition{
    position: absolute;
    bottom:0px
}

@media only screen and (min-width : 480px) {

}

.ProgressBarContainer{
    position: absolute;
  top: 50%;
  left: 45%;
  font-size: 20px;
  color: black;
  /* transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); */
  /* background-color: white; */
  width: 90%;
  padding: 10px;
}

.overlay01{
    position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.anchorClass{
    background: linear-gradient(88.47deg, #4DD4FF 0%, #2F5CCF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }