.landingPageContainer{
    margin:0px 16px;
    min-height: 100vh;
}

.landingPageContainer fieldset,
.landingPageContainer:hover fieldset {
  border-color: transparent !important;
}

.cardsContainer{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
   min-height: 90%;
   z-index: 99999;
}

.cardsRow{
    width:48%;
}

.individualCard{

}

.individualCard p{
    text-align: center;
    margin:0px;
    margin-top:8px;
    margin-bottom: 16px;
    font-weight: 400;
font-size: 14px;
line-height: 20px;
}

.bottomTextContainer{
    font-size: 10px;
line-height: 12px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.1px;
color:#ADA6AE;
padding-bottom:21px;
}

.bottomTextContainer span{

/* position: absolute; */
/* bottom:0px; */
}

.blueFloatingDiv{
    position: absolute;
width: 100px;
height: 100px;
right: 20%;
top: 40%;
background: #25C8D2;
filter: blur(120px);
z-index:1;
}

.noDataFoundText{
    font-weight: 400;
font-size: 16px;
line-height: 24px;
text-align: center;
}

.anchorTagClass{
    text-decoration: underline;
    text-underline-position: under;
    background: linear-gradient(88.47deg, #4DD4FF 0%, #2F5CCF 100%);
    background-position: bottom;
    background-size: 100% 1px;
    background-repeat: no-repeat;
    text-decoration: none;

}

.anchorClass{
    background: linear-gradient(88.47deg, #4DD4FF 0%, #2F5CCF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
  
}
